import { Member } from '@/types/member';

import { apiClient } from './client';

export type GetMembersData = { teamId: string | string[] | undefined };
export type ApiResponseGetMembers = {
  success: boolean;
  members: Member[];
};
export async function getMembers({ teamId }: GetMembersData) {
  const result = await apiClient().get(`/settings/teams/${teamId}/members`);
  return result?.data;
}

//create member
export type CreateMemberData = { emails: string[]; teamId: string };
export type ApiResponseCreateMember = {};
export async function createMember({ emails, teamId }: CreateMemberData) {
  const result = await apiClient().post(`/settings/teams/${teamId}/members`, {
    emails,
  });
  return result?.data;
}

//delete member
export type DeleteMemberData = { memberId: string; teamId: string };
export async function deleteMember({ memberId, teamId }: DeleteMemberData) {
  const result = await apiClient().delete(
    `/settings/teams/${teamId}/members/${memberId}`
  );
  return result?.data;
}

export type ResendInviteData = { memberId: string };
export async function resendInvite({ memberId }: ResendInviteData) {
  const result = await apiClient().post(`/settings/invite/${memberId}`);
  return result?.data;
}

export type AddProjectsToTeamData = { teamId: string; projects: string[] };
export async function addProjectsToTeam({
  teamId,
  projects,
}: AddProjectsToTeamData) {
  const result = await apiClient().post(`/settings/teams/${teamId}/projects`, {
    projects,
  });
  return result.data;
}
