export const PAGE_TITLES = {
  AUTHENTICATION: 'Authentication',
  ACCOUNT_ACCESS_TOKENS: 'Account Access Tokens',
  BILLING_INFO: 'Billing Info',
  CONNECT: 'Connect',
  CONNECTED_ACCOUNTS: 'Connected Accounts',
  DATA_PROCESSING: 'Data Processing Addendum',
  DATA_RETENTION: 'Data Retention',
  DEFAULT: 'Rollbar',
  EMAIL_SETTINGS: 'Email Settings',
  FIX: 'Fix',
  GENERAL: 'General',
  IDENTITY_PROVIDER: 'Identity Provider',
  IMPROVE: 'Improve',
  INVOICE: 'Invoice',
  INVOICES: 'Invoices',
  MANAGE_USER: 'Manage User',
  PEOPLE: 'Affected People',
  PLAN: 'Your Plan',
  PROJECTS: 'Projects',
  PROJECTS_ACCESS_TOKENS: 'Project Access Tokens',
  RQL: 'RQL',
  SAML: 'SAML Identity Provider',
  SUBSCRIPTION: 'Subscription',
  TEAMS: 'Teams',
  THIRD_PARTY_DATA_ACCESSS: '3rd party Data Access',
  USAGE: 'Usage',
  USERS: 'Users',
  USER_STATUS: 'User Status',
  COMPARE: 'Compare Plans',
};

export const LOGIN_PATH = 'login';
export const AUTHENTICATION_COOKIE_NAMES = ['auth_tkt', 'sAccessToken'];

export const DOCS_URL = 'https://docs.rollbar.com/docs';
export const DOCS_URL_PRIVACY_POLICY = `${DOCS_URL}/privacy-policy`;
export const DOCS_URL_TERMS_OF_SERVICE = `${DOCS_URL}/terms-of-service`;

export const CONTACT_US_URL = 'https://rollbar.com/contact';

export enum ReCaptchaAction {
  LOGIN = 'login',
  SIGNUP = 'signup',
}
