import classNames from 'classnames';

export type PulsarProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'purple' | 'orange';
  className?: string;
};

export function Pulsar({
  size = 'md',
  color = 'purple',
  className,
}: PulsarProps) {
  return (
    <span
      className={classNames(
        'relative flex',
        {
          'h-3 w-3': size === 'sm',
          'h-6 w-6': size === 'md',
          'h-8 w-8': size === 'lg',
          'h-11 w-11': size === 'xl',
        },
        className
      )}
    >
      <span
        className={classNames(
          'animate-pulsar absolute inline-flex h-full w-full rounded-full opacity-75',
          {
            'bg-orange-400': color === 'orange',
            'bg-purple-400': color === 'purple',
          }
        )}
      />
      <span
        className={classNames(
          'relative inline-flex h-full w-full rounded-full',
          {
            'bg-orange-600': color === 'orange',
            'bg-purple-600': color === 'purple',
          }
        )}
      />
    </span>
  );
}
