import { Menu } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useState } from 'react';

import { Flags } from '@/lib/flags';
import useAccountUrl from '@/lib/useAccountUrl';
import useFlag from '@/lib/useFlag';
import { UserType } from '@/types/user';

import AccountSwitch from './AccountSwitch';
import { LogOutButton } from './LogOutButton';
import MenuItem from './MenuItem';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;
const olympusUrl = process.env.NEXT_PUBLIC_WEBSITE_BASE;

type UserMenuProps = {
  user: UserType;
  accountSlug: string;
};

export default function UserMenu({ user, accountSlug }: UserMenuProps) {
  const [accountSwitchOpen, setAccountSwitchOpen] = useState(false);
  const accountSettingsUrl = useAccountUrl('/settings');
  const projectsUrl = useAccountUrl('/projects');

  const emailSettingsUrl = `${baseUrl}/settings/email`;
  const connectedAccountsUrl = `${baseUrl}/settings/integrations`;

  const authenticationUrl = useFlag(Flags.USER_SETTINGS_AUTHENTICATION)
    ? `${olympusUrl}/settings/authentication`
    : `${baseUrl}/settings/authentication`;

  return (
    <Menu.Items>
      <div className="h-2.5 w-2.5 absolute bg-white left-1 bottom-4 rotate-45 transform" />
      <div className="bg-white shadow-md rounded-sm">
        <div className="flex flex-col pb-2 relative w-64 text-black cursor-pointer">
          <MenuItem
            href={`${baseUrl}/settings`}
            email={user.email}
            subtitle="Manage user settings"
            noLeftPadding={true}
            className="font-bold"
          >
            {user.name}
          </MenuItem>
          <div className="pt-0.5 pb-2 border-b border-gray-100">
            <MenuItem href={emailSettingsUrl}>Email Settings</MenuItem>
            <MenuItem href={authenticationUrl}>Authentication Options</MenuItem>
            <MenuItem href={connectedAccountsUrl}>Connected Accounts</MenuItem>
          </div>
          <MenuItem
            href={accountSettingsUrl}
            subtitle="Manage account settings"
            icon={accountSlug}
            noLeftPadding={true}
            className="font-bold"
          >
            {accountSlug}
          </MenuItem>
          <div className="pt-0.5 pb-2 border-b border-gray-100">
            <MenuItem
              href={`${baseUrl}/settings/accounts/${accountSlug}/teams`}
            >
              Teams
            </MenuItem>
            <MenuItem href={projectsUrl}>Projects</MenuItem>
            {(user.isOwner || user.isSuperadmin) && (
              <MenuItem
                href={`${baseUrl}/settings/accounts/${accountSlug}/subscription`}
              >
                Your Plan and Billing
              </MenuItem>
            )}
            <MenuItem
              href={`${baseUrl}/settings/accounts/${accountSlug}/usage`}
            >
              Usage
            </MenuItem>
          </div>
          {!user.isGuest && (
            <div
              className="pt-2 pb-2 border-b border-gray-100 active:text-blue-600"
              aria-hidden="true"
              onClick={() => setAccountSwitchOpen(true)}
              onMouseEnter={() => setAccountSwitchOpen(true)}
              onMouseLeave={() => setAccountSwitchOpen(false)}
            >
              <MenuItem
                data-testid="account-selector-label"
                noLeftPadding={true}
              >
                <div className="flex flex-row justify-between">
                  <p>Switch or create account</p>
                  <ChevronRightIcon className="h-6 ml-10" />
                </div>
              </MenuItem>
              <div className="absolute left-full bottom-11 flex z-50">
                <div className="relative w-2.5" />
                {accountSwitchOpen && (
                  <AccountSwitch selectedAccount={accountSlug} />
                )}
              </div>
            </div>
          )}
          <div className="pt-2">
            <LogOutButton user={user} />
          </div>
        </div>
      </div>
    </Menu.Items>
  );
}
