import classNames from 'classnames';

export type BlocksProps = {
  size?: 'xs' | 'sm' | 'md';
};

export function Blocks({ size = 'md' }: BlocksProps) {
  return (
    <img
      className={classNames({
        'h-4 w-4': size === 'xs',
        'h-5 w-5': size === 'sm',
        'h-6 w-6': size === 'md',
      })}
      src="/gifs/sdk-loading.gif"
      alt="Loading"
    />
  );
}
