import { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types';
import EmailPasswordWebJs from 'supertokens-auth-react/recipe/emailpassword';
import MultiFactorAuth from 'supertokens-auth-react/recipe/multifactorauth';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy';
import SessionWebJs from 'supertokens-auth-react/recipe/session';
import ThirdParty, {
  Github,
  Google,
} from 'supertokens-auth-react/recipe/thirdparty';
import Totp from 'supertokens-auth-react/recipe/totp';

import { appInfo } from './appInfo';

export const frontendConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      Multitenancy.init({
        override: {
          functions: (oI) => ({
            ...oI,
            getTenantId: async (input) => {
              const { userContext } = input;
              if (userContext?.tenantId) {
                return userContext.tenantId;
              }
              return oI.getTenantId(input);
            },
          }),
        },
      }),
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Github.init(), Google.init()],
        },
      }),
      EmailPasswordWebJs.init(),
      SessionWebJs.init({
        sessionTokenBackendDomain: process.env.NEXT_AUTHN_COOKIE_DOMAIN,
      }),
      MultiFactorAuth.init(),
      Totp.init(),
    ],
  };
};
