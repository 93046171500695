import classnames from 'classnames';

export interface SkeletonProps {
  width?: string;
  height?: string;
  className?: string;
  containerClassName?: string;
  circle?: boolean;
}

export function Skeleton({
  width = 'w-full',
  height = 'h-5',
  circle = false,
  className,
  containerClassName,
}: SkeletonProps) {
  return (
    <div
      className={classnames(
        'flex flex-row items-center',
        height,
        width,
        containerClassName
      )}
    >
      <span className="sr-only">Skeleton Loading Indicator</span>
      <div className="flex flex-col flex-1 h-full w-full">
        <div
          className={classnames(
            'bg-gray-200 animate-pulse bg-gradient-to-r from-gray-200 to-gray-50 h-full w-full',
            circle ? 'rounded-full' : 'rounded',
            className,
            { 'mx-0.5': circle }
          )}
        ></div>
      </div>
    </div>
  );
}
