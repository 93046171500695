import qs from 'qs';

const { NEXT_PUBLIC_ROLLBAR_BASE_URL } = process.env;

export const loginUrl = () => '/login';
export const SIGNUP_URL = '/signup';

export const moxIndexUrl = () => `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/`;

export const googleLoginCallbackUrl = () =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/callback/google_oauth_login/`;

export const githubLoginCallbackUrl = () =>
  `${NEXT_PUBLIC_ROLLBAR_BASE_URL}/callback/github_oauth_login/`;

export const securitySettingsUrl = (account: string) =>
  `/a/${account}/settings/security`;

// saml settings
export const addIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/add`;

export const viewIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp`;

export const editIdentityProviderSettingsUrl = (account: string) =>
  `${securitySettingsUrl(account)}/idp/edit`;

export const userAuthenticationSettingsUrl = () => `/settings/authentication`;

// totp

const TOTP_BASE = '/auth/totp';
export const totpEnterUrl = (nextUrl: string) =>
  `${TOTP_BASE}?${qs.stringify({ next_url: nextUrl })}`;

export const totpRecoverUrl = (nextUrl: string) =>
  `${TOTP_BASE}/recover?${qs.stringify({ next_url: nextUrl })}`;
