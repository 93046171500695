import Portal from '@reach/portal';
import { useTooltip, TooltipPopup } from '@reach/tooltip';
import * as React from 'react';
import '@reach/tooltip/styles.css';

type TooltipProps = {
  label?: React.ReactNode;
  children: React.ReactElement;
  position?: 'right' | 'centered';
  margin?: number | 0;
  backgroundColor?: 'gray' | 'black';
};
const empty = <></>;
export default function TooltipElement({
  label = empty,
  children,
  position,
  ...tooltipProps
}: TooltipProps) {
  return (
    <TriangleTooltip label={label} position={position} {...tooltipProps}>
      <div className="flex items-center justify-center">{children}</div>
    </TriangleTooltip>
  );
}

const rightAlign = (triggerRect: any, tooltipRect: any, margin: number = 0) => {
  const triggerMiddle = triggerRect.bottom - triggerRect.height / 2;

  return {
    left: triggerRect.right + margin,
    top: triggerMiddle - tooltipRect.height / 2,
  };
};

const centerAlign = (triggerRect: any, tooltipRect: any) => {
  const triggerCenter = triggerRect.right - triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

const PositionFnMapping = {
  right: rightAlign,
  centered: centerAlign,
};

function TriangleTooltip({
  children,
  label,
  position = 'centered',
  backgroundColor = 'black',
  margin = 0,
  ...tooltipProps
}: TooltipProps) {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;

  const positionFn = PositionFnMapping[position];

  let left = undefined;
  let top = undefined;
  let rotating = undefined;
  if (triggerRect && position === 'centered') {
    // We subtract 18 here; 8 for the size of the border and 10 for half the size of the icon.
    left = triggerRect.right - triggerRect.width / 2 - 8;
    top = triggerRect ? triggerRect.bottom + window.scrollY : undefined;
    rotating = 0;
  } else if (triggerRect && position === 'right') {
    // We subtract 11 here to make sure the triangle is placed before and not below the tooltip.
    left = triggerRect.right - 11 + margin;
    // Below we adjust for height and rotate the triangle.
    top = triggerRect.bottom - 18;
    rotating = -90;
  }

  const background = backgroundColor === 'gray' ? '#343F55' : '#000000';

  return (
    <React.Fragment>
      {React.cloneElement(children, trigger)}
      {isVisible && (
        // This is the triangle.
        <Portal>
          <div
            className="absolute w-0 h-0 z-tooltip"
            style={{
              // TODO: Convert this to Tailwind css.
              left,
              top,
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderBottom: '8px solid' + background,
              transform: 'rotate(' + rotating + 'deg)',
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        {...tooltip}
        label={label}
        position={(triggerRect, tooltipRect) =>
          positionFn(triggerRect, tooltipRect, margin)
        }
        className="rounded-md !z-tooltip"
        style={{
          // TODO: Convert this to Tailwind css.
          background,
          border: 'none',
          color: '#ECF1F9',
          padding: '8px 12px',
        }}
        {...tooltipProps}
      />
    </React.Fragment>
  );
}
