import { useRouter } from 'next/router';
import {
  signOut,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';

import { loginUrl } from '@/lib/urls';
import * as Waiting from '@/rollbar-ui/Waiting';
import { UserType } from '@/types/user';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

export function LogOutButton({ user }: { user: UserType }) {
  const router = useRouter();
  const classnames =
    'pr-4 pb-1.5 pl-6 hover:text-blue-600 items-center no-underline text-black cursor-pointer text-sm';
  const logOutText = user.isGuest ? 'Exit Demo' : 'Log out';
  const session = useSessionContext();
  if (session.loading) {
    return <Waiting.Spinner />;
  }

  if (session.doesSessionExist) {
    return (
      <button
        className={classnames}
        onClick={async () => {
          await signOut();
          router.push(loginUrl());
        }}
      >
        {logOutText}
      </button>
    );
  }

  return (
    <form
      method="POST"
      action={`${baseUrl}/logout`}
      className="pr-4 pb-1.5 pl-6 hover:text-blue-600 items-center no-underline text-black cursor-pointer text-sm"
    >
      <input
        type="submit"
        value={logOutText}
        className="bg-white cursor-pointer"
      />
    </form>
  );
}
