import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useCallback } from 'react';
import { FunctionComponent } from 'react';

import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

export type Props = {
  actionButtonText?: string;
  cancelButtonText?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  hasCloseButton?: boolean;
  isOpen: boolean;
  onAction?: () => void;
  onCancel?: () => void;
  testId?: string;
  title?: React.ReactNode;
  variant?: any;
  modalClassName?: string;
  showFooter?: boolean;
  actionButtonDisabled?: boolean;
};

const Modal: FunctionComponent<Props> = ({
  actionButtonText,
  cancelButtonText,
  children,
  footer,
  hasCloseButton = false,
  isOpen,
  onAction,
  onCancel,
  title,
  variant = 'primary',
  modalClassName,
  showFooter = true,
  actionButtonDisabled,
}) => {
  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-900 opacity-75"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center lg:items-center justify-center lg:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="translate-y-1/2 scale-100"
              enterTo="translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="translate-y-0 scale-100"
              leaveTo="translate-y-full scale-100"
            >
              <Dialog.Panel
                className={classNames(
                  'overflow-visible transform bg-white p-6 text-left align-middle shadow-xl transition-all',
                  'rounded-t-2xl lg:rounded-2xl',
                  'w-full max-w-2xl',
                  modalClassName
                )}
              >
                {(title || hasCloseButton) && (
                  <ModalHeader
                    hasCloseButton={hasCloseButton}
                    onClose={onCancel}
                    title={title}
                    variant={variant}
                  />
                )}
                {/* Content */}
                <div className="px-2 text-gray-500">{children}</div>

                {showFooter && (
                  <ModalFooter
                    actionButtonText={actionButtonText}
                    cancelButtonText={cancelButtonText}
                    footer={footer}
                    onAction={onAction}
                    onCancel={onCancel}
                    variant={variant}
                    actionButtonDisabled={actionButtonDisabled}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
